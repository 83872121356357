<!--
 * @Author: 王广婷
 * @Date: 2020-04-09 20:33:48
 * @LastEditors: 王广婷
 * @LastEditTime: 2020-04-29 15:25:06
 -->
<template>

    <div class="mainpage">
        <div class="xcbg">
            <img src="@/assets/nh/yaoqing.jpg" alt="">
        </div>
        <div class="hdgz">
            <img src="@/assets/nh/huodongrule.png" alt="" @click="showhdgz=true">
        </div>
        <div class="ins-btn">
            <div class="ins-btn-left">
                <img src="@/assets/nh/longqx.png" alt="" @click="$router.push({path:'/promoteDetail',query:{name:'long'}})">
            </div>
            <div class="ins-btn-right">
                <img src="@/assets/nh/shortqx.png" alt="" @click="$router.push({path:'/promoteDetail',query:{name:'short'}})">
            </div>
        </div>
        <div class="bot-btn">
            <div class="bot-btn-left">
                <img src="@/assets/nh/wanyuanyiliao.png" alt="" @click="$router.push({path:'/servicePack'})">
            </div>
            <div class="bot-btn-right">
                <img src="@/assets/nh/tuiguang.png" alt="" @click="$router.push({path:'/promoteDetail',query:{name:'tgf'}})">
            </div>
        </div>
        <md-landscape v-model="showhdgz"
                  class="jfbox">
          <div class="s-box">
            <img src="@/assets/nh/hdgzsm.jpg">
          </div>
        </md-landscape>
    </div>

</template>
<script>
import config from '@/config'
import { wechatshare } from '@/lib/wechat_share'
const { redirect_uri } = config
export default {
  data () {
    return {
      showhdgz: false,
      sharelogo: require('@/assets/nh/tgsharelogo.jpg')
    }
  },
  created () {
    this.share()
  },
  methods: {
    share () {
      let shareurl = `${redirect_uri}/promote`
      wechatshare(
        '首期限时三重大礼,最高价值20000元',
        `一重礼推广费高达84%,二重礼价值2万元服务包,三重礼额外388红包！`,
        redirect_uri + this.sharelogo,
        encodeURI(shareurl),
        redirect_uri
      )
    }
  }
}
</script>
<style lang="stylus" scoped>
    .mainpage{
      width 100%
      background #5c20c0
      /deep/ .md-landscape-content{
          width 9rem
          height 13rem
      }
      .xcbg{
          width 100%
      }
      .hdgz{
          position absolute
          width: 1.2rem;
            right: 0;
            top: 0.7rem;
      }
      .ins-btn{
          display flex
          justify-content space-evenly
          position absolute
          width 100%
          top 13.5rem
          margin 0 0.3rem
          .ins-btn-left{
            height 0.8rem
            width: 2.5rem;
          }
            .ins-btn-right{
                width: 2.5rem;
                height 0.8rem
            }
      }
        .bot-btn{
            display flex
            justify-content space-evenly
            position relative
            top -2rem
            width 100%
            background #5c20c0
            .bot-btn-left{
                width 3.5rem
                height 1rem
                bottom 0rem
            }
            .bot-btn-right{
                width 3.5rem
                height 1rem
                bottom 0rem
            }
        }

    }
</style>
